import { useContext } from "react";
import { Context } from "../providers/Context";
import { OnboardContext } from "../providers/OnboardContext";
import { useNavigate } from "react-router-dom";
import useAxios, { baseAxios } from "./useAxios";
import useAuth from "./useAuth";
import * as Sentry from "@sentry/react";
import { houseSizeOptions } from "pages/OnboardLocation/components/LocationForm/data";

const useApi = () => {
  // Routes
  const USER = "user/";
  const PURCHASES = "purchases/";
  const PURCHASES_AND_PRODUCTS = "purchases-products/";
  const JOBS = "jobs";
  const CHECKOUT = "checkout/";
  const CONFIRM_CHECKOUT = "checkout/confirm/";
  const ONBOARD_CONTACT = "onboarding/";
  const ONBOARD_NAME = "onboarding/name/";
  const ONBOARD_MEASUREMENT = "measurements/";
  const ONBOARD_PRICES = "price-options/";
  const BILLING_PORTAL = "billing-portal/";
  const CONTACT_US = "contact_us/";
  const CONTACT_US_MEMBER = "contact-us-member/";
  const ADDRESS = "address/";
  const AGENT_ONBOARD = "agent-sign-up/";
  const ERROR_MESSAGE = "Something went wrong, please try again.";

  const navigate = useNavigate();
  const authAxios = useAxios();
  const { handleSignUp, handleSignUpConfirmation, handleAutoSignIn } =
    useAuth();

  const {
    setUserData,
    userData,
    setIsLoading,
    setIsOnboarded,
    openErrorSnackbar,
    openInfoSnackbar,
  } = useContext(Context);
  const {
    saveOnboardData,
    clearOnboardData,
    getOnboardData,
    setIsCalculating,
    setLocationResponse,
    setLocationResponseCode,
  } = useContext(OnboardContext);

  const getUserInfoApi = async () => {
    try {
      const response = await authAxios.get(`${USER}`);
      // need to get address separately
      const addressResponse = await authAxios.get(`${ADDRESS}`);
      setUserData({ ...response?.data, addresses: addressResponse?.data });
      setIsOnboarded(true);
      return { status: "Success" };
    } catch (error) {
      Sentry.captureException(error);

      if (error.response.data?.detail === "User profile is incomplete") {
        setIsOnboarded(false);
        return { status: "incomplete" };
      }
      // console.log(error, "with getting user info");
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfileApi = async ({ first_name, last_name }) => {
    try {
      setIsLoading(true);
      const response = await authAxios.put(`${USER}`, {
        first_name,
        last_name,
      });

      setUserData({ ...userData, ...response?.data });
      setIsLoading(false);
      openInfoSnackbar("Profile updated successfully");
      return { error: false, data: response.data };
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      openErrorSnackbar(ERROR_MESSAGE);
    }
  };

  const getJobApi = async (status) => {
    // date for request is in UTC time. GTC Month starts with 0 so add 1
    const date = new Date();
    const formattedDate = `${date.getUTCFullYear()}-${
      date.getUTCMonth() + 1
    }-${date.getUTCDate()} `;
    const filter =
      status === "upcoming"
        ? `from_start_date=${formattedDate}`
        : `before_date=${formattedDate}`;

    try {
      setIsLoading(true);
      const response = await authAxios.get(`${JOBS}?${filter}`);
      setIsLoading(false);
      return { error: false, data: response?.data };
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getPurchasesApi = async () => {
    try {
      setIsLoading(true);
      const response = await authAxios.get(`${PURCHASES}`);
      setIsLoading(false);

      return { data: response.data };
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
      setIsLoading(false);
    }
  };

  const getPurchasesAndProductsApi = async () => {
    try {
      setIsLoading(true);
      const response = await authAxios.get(`${PURCHASES_AND_PRODUCTS}`);
      setIsLoading(false);
      return { data: response.data };
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
      openErrorSnackbar(ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  const getBillingPortalApi = async () => {
    try {
      const response = await authAxios.get(`${BILLING_PORTAL}`);
      return { data: response.data };
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
      openErrorSnackbar(ERROR_MESSAGE);
      throw error;
    }
  };

  //////// Onboard Calls /////////
  const createContactOnboard = async ({ email, phone }) => {
    // console.log("Saving contact information");
    const session_id = getOnboardData("oi");
    const address = getOnboardData("oa");
    const lawn_info = getOnboardData("ols");
    const house_info = getOnboardData("ohs");
    const onboard_selected = getOnboardData("opi");
    try {
      setIsLoading(true);
      const res = await baseAxios.post(`${ONBOARD_CONTACT}`, {
        email,
        phone,
        session_id,
        ...address,
        house_size: house_info?.code,
        lawn_size: lawn_info?.code,
        lawn_sqft: lawn_info?.lawn_sqft,
        onboard_selected,
      });

      saveOnboardData({
        oe: email,
        oph: phone,
        os: res?.data?.stripe_id,
        oh: res?.data?.hubspot_id,
      });

      setIsLoading(false);
      navigate(`/onboard/information`);
      return;
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      let errorMessage = ERROR_MESSAGE;
      setIsLoading(false);
      // Error message displayed if user already has an account
      if (error?.response?.status === 409) {
        errorMessage =
          "This phone or email already has a OneOS account, try signing in instead.";
      }
      openErrorSnackbar(errorMessage);
    }
  };

  const createAddressOnboard = async (data, isLocationConfirm = false) => {
    try {
      setIsCalculating(true);

      const res = await baseAxios.post(
        `${ONBOARD_MEASUREMENT}`,
        {
          full_address: data?.full_address,
        },
        { retry: 2 }
      );

      setLocationResponse(res?.data);
      setLocationResponseCode(null);
      // console.log("Getting measurements");
      // console.log("Response for measurement", res?.data);
      if (!isLocationConfirm) {
        saveOnboardData({ oi: res?.data?.session_id });
        return { data: res?.data };
      } else {
        saveOnboardData({ oi: res?.data?.session_id });
        return { data: res?.data };
      }
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      setLocationResponse(error?.response?.data);
      setLocationResponseCode(error?.response?.status);
      switch (error?.response?.status) {
        case 406:
          clearOnboardData();
          return { status: error?.response?.status };

        case 409:
          saveOnboardData({ oi: error?.response?.data?.session_id });

          const message =
            (error?.response &&
              error?.response?.data &&
              error?.response?.data?.message) ||
            ERROR_MESSAGE;

          openErrorSnackbar(message);
          break;

        default:
          openErrorSnackbar(ERROR_MESSAGE);
          return { status: error?.response?.status };
          break;
      }
    } finally {
      setIsCalculating(false);
    }
  };

  const getOnboardPrices = async (data) => {
    let lawn_size = data?.lawn_size
      ? data?.lawn_size?.code
      : getOnboardData("ols");
    let address = data?.address ? data?.address : getOnboardData("oa");
    // console.log("Getting prices");
    try {
      let houseSizeCode = data?.house_size?.code;
      if (data?.house_size?.code === "house_6501_plus") {
        houseSizeCode = houseSizeOptions.find(
          (value) => value.code === "house_4501_6500"
        )?.code;
      }

      const resPrice = await baseAxios.post(`${ONBOARD_PRICES}`, {
        house_size: houseSizeCode,
        lawn_size: lawn_size ? lawn_size : data?.lawnSize?.code,
        state: address?.state,
      });

      setIsCalculating(false);
      // console.log(resPrice, "response for price options");
      saveOnboardData({ options: resPrice?.data });
      navigate("/onboard/custom-package");
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      setIsCalculating(false);
      openErrorSnackbar(ERROR_MESSAGE);
    }
  };
  const createNameOnboard = async ({ first_name, last_name }) => {
    // console.log("Saving name");
    const hubspot_id = getOnboardData("oh");
    const session_id = getOnboardData("oi");

    try {
      setIsLoading(true);
      saveOnboardData({
        ofn: first_name,
        oln: last_name,
      });

      const nameRes = await baseAxios.post(`${ONBOARD_NAME}`, {
        hubspot_id,
        first_name,
        last_name,
        session_id,
      });
      // console.log("Name was added");
      setIsLoading(false);
      return nameRes;
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      setIsLoading(false);
      openErrorSnackbar(ERROR_MESSAGE);
    }
  };
  const createPaymentOnboard = async () => {
    const stripe_id = getOnboardData("os");
    const stripe_price_id = getOnboardData("op");
    const session_id = getOnboardData("oi");

    // console.log("Setting up payment");

    try {
      setIsLoading(true);
      const res = await baseAxios.post(`${CHECKOUT}`, {
        prices: stripe_price_id,
        mode: "subscription",
        stripe_id,
        session_id,
      });

      setIsLoading(false);
      return { data: res?.data };
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
      setIsLoading(false);
      openErrorSnackbar(ERROR_MESSAGE);
    }
  };
  const confirmCheckout = async () => {
    const stripe_price_id = getOnboardData("op");
    const hubspot_id = getOnboardData("oh");
    const session_id = getOnboardData("oi");

    try {
      setIsLoading(true);
      // console.log("Confirming checkout");
      const res = await baseAxios.post(`${CONFIRM_CHECKOUT}`, {
        price_id: stripe_price_id,
        hubspot_id,
        session_id,
      });

      setIsLoading(false);
      return { status: res?.status };
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
      setIsLoading(false);
      openErrorSnackbar(ERROR_MESSAGE);
    }
  };

  const createAccountOnboard = async ({ password, confirmPassword }) => {
    const email = getOnboardData("oe");

    try {
      setIsLoading(true);
      await handleSignUp({ username: email, password });
      // console.log("Sign up set up in auth");
      setIsLoading(false);
      navigate("/onboard/confirm");
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      setIsLoading(false);
      const errorMessage =
        error && error?.message ? error?.message : ERROR_MESSAGE;
      setIsLoading(false);
      openErrorSnackbar(errorMessage);
    }
  };

  const createAgentAccountOnboard = async (email) => {
    try {
      setIsLoading(true);
      const res = await baseAxios.post(`${AGENT_ONBOARD}`, { email: email });
      // console.log("Sign up set up in auth");
      setIsLoading(false);
      return { status: res?.status };
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      setIsLoading(false);
      const errorMessage =
        error && error?.message ? error?.message : ERROR_MESSAGE;
      setIsLoading(false);
      openErrorSnackbar(errorMessage);
      return { status: 500, message: errorMessage };
    }
  };

  const confirmAccount = async ({ code }) => {
    const email = getOnboardData("oe");
    try {
      setIsLoading(true);
      await handleSignUpConfirmation({
        username: email,
        code,
      });
      // console.log("Account confirmed");
      await handleAutoSignIn();
      setIsLoading(false);
      clearOnboardData();
      navigate("/");
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      const errorMessage = error ? error?.message : ERROR_MESSAGE;
      setIsLoading(false);
      openErrorSnackbar(errorMessage);
    }
  };

  //// Contact us ////
  const createContactUs = async (data) => {
    // console.log("Sending createContactUs information");
    try {
      setIsLoading(true);
      const res = await baseAxios.post(`${CONTACT_US}`, data);
      setIsLoading(false);
      return res;
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      setIsLoading(false);
      openErrorSnackbar(ERROR_MESSAGE);
    }
  };
  ////////////////////
  const createContactUsMember = async (data) => {
    // console.log("Sending createContactUs information");
    try {
      setIsLoading(true);
      await authAxios.post(`${CONTACT_US_MEMBER}`, data);
      setIsLoading(false);
      return;
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error occurred:", error);
      setIsLoading(false);
      openErrorSnackbar(ERROR_MESSAGE);
    }
  };

  return {
    getUserInfoApi,
    getOnboardData,
    updateProfileApi,
    getBillingPortalApi,
    getPurchasesApi,
    getJobApi,
    createContactOnboard,
    createAddressOnboard,
    createNameOnboard,
    createPaymentOnboard,
    createAccountOnboard,
    confirmCheckout,
    createContactUs,
    createContactUsMember,
    confirmAccount,
    getOnboardPrices,
    createAgentAccountOnboard,
    getPurchasesAndProductsApi,
  };
};

export default useApi;
